/* Backgound and footer size */
.main {
  background-color: #212428;
  height: 520px;
  padding-top: 40px;
  position: relative;
  color: white;
}

.grid {
  padding: 30px;
  display: grid;
  grid-template-columns: auto 33%;
}

.astraTitle {
  color: white;
}
/* 
    Astra left 
    <-- 
*/
.astraBox {
  position: relative;
  padding: 20px;
  margin-left: 80px;
}
.astra {
  padding-top: 30px;
  /* Font */
  /* font-size: 14px; */
  line-height: 24.03px;

  width: 500px;
}

/* Navbar link */
.navLink {
  position: absolute;
  bottom: -60px;
  display: inline-flex;
  width: 150%;
}
.navLink a {
  font-weight: 500;
  font-size: 16px;
  line-height: 30.04px;

  text-align: center;
  padding-right: 30px;

  text-decoration: none;
  color: white;
}

.navLink a:active {
  color: #eda426;
}
/* 
    Contact us 
    -->
*/
.contactBox {
  padding: 20px;
  position: relative;
}
.contactArea {
  position: absolute;
}
/* Icon box */
.iconBox {
  display: inline-flex;
  min-width: 250px;
  justify-content: space-between;
  padding-top: 30px; /* Control distance from contact us*/
}

/* Email */
.mail {
  padding-top: 30px;
  margin: 0;
}

/* 
    Bottom astra logo
*/
.astraLine {
  position: absolute;
  width: 100%;
  bottom: 30px;
}

.imageBox {
  width: 150px;
  margin-left: 100px;
  margin-right: 100px;
}

/* Mobile css */
@media (max-width: 960px) {
  .main {
    height: fit-content;
  }

  .grid {
    grid-template-columns: 100%;
  }

  .astraBox {
    margin-left: 0px;
  }

  .astraTitle {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    text-align: center;
  }

  .astra {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: center;
  }

  .navLink {
    display: grid;
    grid-template-columns: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    text-align: center;
  }

  .navLink a {
    padding: 0;
  }

  .contactBox {
    margin-top: 120px;
    text-align: center;
    margin-bottom: 160px;
  }

  .contactArea {
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .iconBox {
    padding-top: 10px;
  }

  .iconButton {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mail {
    padding-top: 10px;
  }

  .imageBox {
    margin-left: 0px;
    margin-right: 0px;
  }
}
