// Override Bootstrap variables here
$display-font-weight: 700;
$display1-size: calc(min(4.5rem, 7vh));
$display2-size: calc(min(2.5rem, 7vw));
$display3-size: calc(min(1.5rem, 5vw));
$lead-font-size: 1.5rem;
$lead-font-weight: 300;
$font-family-sans-serif: $roboto;
$font-weight-base: 300;
$font-size-base: 1.05rem;
$headings-font-family: $inter;
$headings-font-weight: 700;

$primary: #cf4b08;

// buttons styling
$btn-border-radius: 0px;
$btn-border-radius-sm: 0px;
$btn-border-radius-lg: 0px;
$btn-hover-bg-shade-amount: 10%;
$btn-hover-bg-tint-amount: 10%;
$btn-hover-border-shade-amount: 10% !default;
$btn-hover-border-tint-amount: 10% !default;

// Import Bootstrap and its default variables
@import "bootstrap/scss/bootstrap";
